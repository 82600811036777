.DemosList{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
}

.DemoElement{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 75px;
    border-bottom: 1px solid #d0d0d0;
    padding-left: 25px;
    padding-right: 25px;
    cursor: pointer;
}

.DemoElement:hover{
    background-color: rgba(255,255,255, 0.25);
}

.DemoElement > div{
    box-sizing: border-box;
}

.DemoElement > div:first-child{
    display: flex;
    flex-direction: column;
}

.DemoElement__nombre{
    width: 50%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
}

.DemoElement__configuracion{
    width: 50%;
    display: flex;
    flex-direction: row;
}

.DemoElement__configuracion span{
    width: 33.33%;
}
