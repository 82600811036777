.DemoCard {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: black;
    color: white;
    height: 100%;
}

.DemoCardPerfil{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: black;
    color: white;
    width: 100%;
}

.DemoCardPerfil__nombre{
    font-size: 1.1em;
    margin-bottom: 10px;
}

.DemoCardPerfil__foto{
    width: 300px;
    display: flex;
    margin-bottom: 10px;
    align-content: center;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.DemoCardPerfil__foto img{
    max-width: 100%;
    max-height: 100%;
}

.DemoCardPerfil__datos{
    font-size: 1.0em;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    flex-wrap: nowrap;
    align-items: flex-end;
    width: 100%;
    padding-right: 15px;
}

.DemoCardPerfil__datos span{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
}

.DemoCardPerfil__datos span sub{
    margin-right: 5px;
    font-style: italic;
    color: gray;
}

.DemoCardPerfil__configuracion{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 0.75em;
    margin-bottom: 15px;
}

.DemoCardPerfil__configuracion span{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1em;
}

.DemoCardPerfil__configuracion span:not(:last-child)::after{
    content: "/";
    margin: 0px 5px;
}