@keyframes tilting {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(15deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@keyframes light {
    0% {
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.5;
    }
}

.DemotecaRecord{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    background-color: var(--primary-10);
    color: white;
}

.LeftPanel{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 25%;
    height: 100%;
    padding: 1rem;
    overflow: auto;
}

.RightPanel{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 25%;
    height: 100%;
    padding: 1rem;
}

.CenterPanel{
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    padding: 1rem;
}

.CenterPanel p{
    margin: 0;
    white-space: pre-wrap;
    height: calc(100% - 120px);
    padding: 30px;
    box-sizing: border-box;
    overflow: auto;
}

.CenterPanel .GrabarSection{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.CenterPanel  button.GrabarButton, .CenterPanel #CentralPanel_stopRecording{
    background: var(--secondary-8);
    font-family: inherit;
    height: 40px;
    color: white;
    border: none;
    box-shadow: 0px 0px 10px var(--primary-7);
    border-radius: 20px;
}

.GrabarTimer {
    display: flex;
    align-items: center;
}

.GrabarTimer select{
    padding: 3px;
    background: transparent;
    color: white;
    border: 0px;
    font-size: inherit;
}

.GrabarTimer select option{
    background: var(--primary-10);
    border: 0px;
}

.CenterPanel-textbuttons button{
    padding: 5px 15px;
    background-color: var(--primary-5);
    color: white;
    border: none;
    cursor: pointer;
    margin: 0px 10px;
    border-radius: 5px;
    font-family: inherit;
}

.CenterPanel-counter{
    width: 100%;
    display: flex;
    font-variant-numeric: tabular-nums;
}

.CenterPanel-counter-bar{
    width: 100%;
    height: 20px;
    background-color: var(--primary-5);
    border-radius: 10px;
}

.CenterPanel-counter-bar > div{
    height: 100%;
    background-color: var(--green-5);
}

.LeftPanel .ChoicesBox input{
    display: none;
}

.LeftPanel .ChoicesBox label{
    border: 1px solid var(--primary-8);
    background-color: var(--primary-8);
    color: var(--primary-5);
    padding: 7px 27px;
    border-radius: 8px;
    margin: 10px 5px;
    display: inline-block;
    cursor: pointer;
}

.LeftPanel .ChoicesBox label.checked{
    background-color: var(--green-4);
    color: var(--primary-9);
    box-shadow: 0px 0px 10px var(--primary-4);
}

.RightPanel button{
    height: 40px;
    border: 1px solid var(--primary-8);
    border-radius: 20px;
    background: var(--primary-8);
    color: white;
    box-shadow: 0px 0px 10px var(--green-4);
    font-family: inherit;
    cursor: pointer;
}

.RightPanel .mixingIndicator{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    animation: light 1s infinite;
}

.RightPanel .mixingIndicator span{
    margin-left: 15px;
}

.EcuchaTuMezcla .AudioGoDemosComponent-bar{
    background-color: var(--green-5);
}

.DemoRecord__breadcrumb{
    display: inline-block;
    background: var(--green-2);
    color: var(--primary-9);
    padding: 5px 10px;
    border-radius: 10px;
}



.TimerClock{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 150px;
    font-weight: bold;
}