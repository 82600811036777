.AudioGoDemosComponent{
    display: flex;
    flex-direction: column;
    justify-content:space-evenly;
}

.AudioGoDemosComponent-bar{
    width: 100%;
    background: var(--secondary-5);
    display: flex;
    justify-content: flex-start;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}

.AudioGoDemosComponent-bar > div{
    height: 20px;
    background: var(--secondary-10);
}

.AudioGoDemosComponent-controls{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.AudioGoDemosComponent-controls{
    font-family: inherit;
    font-variant-numeric: tabular-nums;
}

.AudioGoDemosComponent-bar-range{
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    position: absolute;
    background: transparent;
}

.AudioGoDemosComponent-bar-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 20px;
    height: 20px;
  }