:root {
    --CRM-Menu-height: 50px;
    --Dashboard-padding: 15px;
    --Card-table-margin: 5px;
    --Data-table-margin: 5px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes zoomIn {
    from {
        transform: scale(0.75);
        opacity: 0;
        
    }

    to {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes updating {
    0% {
        background: #efefef;
    }

    50% {
        background: #dedede;

    }

    100% {
        background: #efefef;
    }

}

@keyframes slideIn {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

.CustomerRelationManager {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: stretch;
    height: 100%;
    overflow: auto;
    width: 100%;
}


.Menu {
    height: var(--CRM-Menu-height);
    color: black;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    align-items: stretch;
    justify-content: flex-start;
    background: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.Menu>span {
    cursor: pointer;
    padding: 4px 20px;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    align-content: center;
    align-items: center;
}

.Projects,
.Clients{
    height: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    height: calc(100% - var(--CRM-Menu-height));
    background-color: #f2eeff;
    position: relative;
}

.Projects > svg, .Clients > svg{
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
}

.Dashboard{
    display: flex;
    justify-content: flex-start;
    height: calc(100% - var(--CRM-Menu-height));
    background-color: #f2eeff;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: var(--Dashboard-padding);
    overflow: auto;
}

.Dashboard .Card:not(.Cliente-card){
    width: calc(50% - 30px);
    min-height: 400px;
}

.Dashboard .Card:not(.Cliente-card) > div{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-size: 14px;
    flex-wrap: wrap;
}

.Dashboard-top-filter{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
    align-items: center;
}

.Dashboard-top-filter input{
    padding: 10px;
    font-family: inherit;
    margin: 0px 10px;
}

.Dashboard-top-filter button{
    padding: 10px 20px;
    background: var(--purple);
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 0.75em;
    cursor: pointer;
    margin: 0px 10px;
}

.Card{
    background: white;
    margin: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: fadeIn 0.75s;
}

.Card > h2{
    text-align: right;
}

.Card > div{
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    align-items: flex-start;
}

.Card table{
    border-collapse: collapse;
    margin: var(--Card-table-margin);
}


.Card table th{
    padding: 10px;
    border: 1px solid #dfdfdf;
}

.Card table td{
    padding: 10px;
    border: 1px solid #dfdfdf;
}

.Card table td:last-child{
    text-align: right;
    font-variant-numeric: tabular-nums;
}

.Card table td.currency::before{
    content: "$ ";
}

.Dashboard-detalle-clientes{
    width: 100%;
}

.Dashboard-detalle-clientes tbody tr td:nth-child(2){
    width: 50%;
}

.Dashboard-detalle-clientes tbody tr td:nth-child(3){
    width: 140px;
    font-variant-numeric: tabular-nums;
}

.Filters {
    width: 300px !important;
    background: white;
    color: black;
    min-width: 300px;
    padding: 0px 10px;
    overflow: auto;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.slideIn{
    animation: slideIn 0.5s;
}

.Filters label {
    display: block;
    margin: 10px 0;

}

.Filters input,
.Filters select,
.Filters textarea {
    width: 100%;
    padding: 5px;
    border: 1px solid #dfdfdf;
    background: white;
    color: black;
    font-size: 1em;
    text-align: center;
    box-sizing: border-box;
}

.Filters .buttons{
    background-color: white;
    border-top: 1px solid #dfdfdf;
    position: sticky;
    bottom: 0px;
    margin-top: 20px;
}

.Filters button {
    background: var(--purple);
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1em;
    border: none;
    margin: 15px 10px;
}

.Results {
    overflow: auto;
    box-sizing: border-box;
    
    width: 100%;
}

.Results table {
    animation: fadeIn 0.5s;
}


.Results svg {
    animation: zoomIn 0.5s;
}

.Results table {
    border-collapse: collapse;
    margin-top: var(--Data-table-margin);
}


.Results table thead{
    position: sticky;
    top: calc(0px - var(--Data-table-margin));
    z-index: 1;
}

.Results table thead th:nth-child(2){
    position: sticky;
    top: calc(0px - var(--Data-table-margin));
    left: 0px;
}

.Results tbody td,
.Results thead th {
    border: 0.5px solid #dfdfdf;
}
.Results tbody td{
    background: #fafafa;
}
.Results thead th {
    background: var(--green-3);
}

.Results tbody tr:nth-child(odd) td:not(:first-child) {
    background: white;
}

.Results tbody tr:hover td:not(:first-child) {
    background: var(--green-2);
}


.Results thead th {
    padding: 10px;
}

.Results th:first-child,.Results td:first-child{
    background: transparent;
    border: none;
    padding: 10px;
}

.Results tbody td:nth-child(2){
    position: sticky;
    left: 0px;
}

.Results td input,
.Results td select,
.Results td textarea {
    font-family: inherit;
    width: 100%;
    padding: 10px 5px;
    border: none;
    background: transparent;
    color: black;
    font-size: 1em;
    text-align: left;
    box-sizing: border-box;
}

.Results td input:disabled,
.Results td select:disabled,
.Results td textarea:disabled {
    animation: updating 0.75s infinite;
}

.Fechas{
    display: flex;
    justify-content: flex-start;
    height: calc(100% - var(--CRM-Menu-height));
    background-color: #f2eeff;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 30px;
    overflow: auto;
    position: static;
}

.VistaFechas{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    background-color: white;
    border-radius: 10px;
    padding: 15px;
    width: 100%;
    margin-bottom: 40px;
}

.VistaFechas > div{
    width: 100%;
    display: table;
}


.RegistroFecha{
    display: table-row;
}

.RegistroFecha:hover{
    background: var(--primary-1);
}

.RegistroFecha > div{
    display: table-cell;
    padding: 15px;
}

.RegistroFecha > div:nth-child(1){
    text-align: left;
    text-wrap: nowrap;
    width: 10em;
    font-variant-numeric: tabular-nums;
    
}
.RegistroFecha > div:nth-child(2){
    text-align: left;
    font-weight: bold;
    text-wrap: nowrap;
}
.RegistroFecha > div:last-child{
    text-align: right;
    text-wrap: nowrap;
    width: 75px;
}

.RegistroFecha > div:last-child svg{
    cursor:pointer;
}

.RegistroFecha:not(:last-child) > div{
    border-bottom: 1px solid #dfdfdf;
}

.VistaRegistro{
    position: fixed;
    top: calc(var(--header-height) + var(--CRM-Menu-height));
    right: 0;
    background: white;
    padding: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    height: 100%;
    animation: slideIn 0.5s;
}

.VistaRegistro > div{
    display: table-row;
}

.VistaRegistro > div > label{
    display: table-cell;
    text-align: right;
    vertical-align: top;
    padding: 10px;
}

.VistaRegistro > div > input, .VistaRegistro > div > textarea, .VistaRegistro > div > select, .VistaRegistro > div > span{
    padding: 10px;
    border: 1px solid #dfdfdf;
    background: white;
    color: black;
    font-size: 1em;
    text-align: left;
    box-sizing: border-box;
    font-family: inherit;
    width: 100%;
}

.VistaRegistro > div > span{
    color: gray;
    display: inline-block;
}

.VistaRegistro > svg, .VistaFechas > svg{
    cursor: pointer;
}

.Leyenda{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 5px;
    overflow: auto;
    position: static;
}

.Leyenda > div{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    margin: 5px;
}

.Dashboard-detalle-clientes tbody tr td:nth-child(2) {
    padding: 5px 0px;
}
.Dashboard-detalle-clientes tbody tr td:nth-child(1),
.Dashboard-detalle-clientes tbody tr td:nth-child(3) {
    padding: 0px 10px;
    text-overflow: ellipsis;
    text-align: right;
    width: auto;
}

.Dashboard-detalle-clientes tbody tr td:nth-child(3) {
    text-wrap: nowrap;
    font-size: 0.9em;
}

.ProjectsContainer__Export{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.ProjectsContainer__Export > div{
    background: var(--primary-5);
    color: white;
    padding: 7px 10px;
    border-radius: 10px;
    font-size: 14px;
}

.CustomerRelationManager .Projects .Results .Data table thead th{
    cursor: pointer;
}

.CustomerRelationManager .Clients .Results .Data table thead th{
    cursor: pointer;
}