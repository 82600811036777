:root{
    --purple: rgba(255, 102, 196);
}
.MenuTop{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: black;
    width: 100%;
    height: 100%;
    color: white;
    padding: 20px;
    box-sizing: border-box;
}

.MenuTop-Logo{
    width: 160px;
    margin-left: 20px;
}

.MenuTop-CerrarSesion{
    margin-right: 20px;
    cursor: pointer;
    background-color: var(--purple);
    color: white;
    border-radius: 10px;
    font-family: 'Montserrat-Bold', sans-serif;
    font-size: 14px;
    padding: 10px 15px;
}

.MenuTop-Grupo > span{
    font-size: 18px;
    font-family: 'Montserrat-Bold', sans-serif;
    margin: 0px 15px;
}

.MenuTop-Notification{
    position: fixed;
    background: var(--green-1);
    color: black;
    padding: 20px;
    right: 0px;
    top: var(--header-height);
    z-index: 100;
    box-shadow: 0px 0px 10px 0px var(--green-10);
    border-radius: 0px 0px 0px 15px;
    max-width: 640px;
}