@keyframes slideIn {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes greenbright {
    0% {
        background: var(--green-5);
    }

    100% {
        background: inherit;
    }

}

.ComprasView {
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    padding: 20px;
    position: relative;
    background: var(--secondary-1);
}


.ComprasView h1,
.ComprasView h2,
.ComprasView h3 {
    color: var(--primary-8);
}

.ComprasView button {
    cursor: pointer;
    border: 1px solid var(--green-8);
    background: var(--green-2);
    color: var(--primary-8);
    border-radius: 4px;
    padding: 4px 10px;
    font-size: 16px;
}


.ComprasView .ProductosList>div:first-child,
.ComprasView .ComprasList>div:first-child {
    font-weight: bold;
}

.ComprasView .ProductosList>div:not(:first-child):hover,
.ComprasView .ComprasList>div:not(:first-child):hover {
    background: var(--secondary-2);
}

.ComprasView .ComprasList {
    display: flex;
    flex-direction: column;
}

.ComprasView .ComprasList>div {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 10px;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.ComprasView .ComprasList>div>div:first-child {
    text-align: left;
}

.ComprasView .ComprasList>div>div:not(:first-child) {
    text-align: right;
}

.ComprasView .ComprasList>div>div:last-child>i {
    cursor: pointer;
}

.ComprasView .CompraDetalle {
    position: fixed;
    right: 0px;
    top: var(--header-height);
    background: white;
    height: calc(100% - var(--header-height));
    box-sizing: border-box;
    padding: 20px;
    box-shadow: -2px 0px 5px 0px #ccc;
    width: 50%;
    animation: slideIn 0.5s;
    overflow: auto;
}

.ComprasView .CompraDetalle>div:first-child i {
    cursor: pointer;
}

.ComprasView .CompraDetalle .CompraDetalleList>div {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;
    font-family: monospace;
}

.ComprasView .CompraDetalle .CompraDetalleList>div:last-child {
    margin-top: 20px;
    border-top: 1px solid #ccc;
    padding-top: 10px;
}


.ComprasView .CompraDetalle .CompraDetalleList>div>div:first-child {
    grid-column: 1/3;
    text-align: left;
}

.ComprasView .CompraDetalle .CompraDetalleList>div>div:not(:first-child) {
    text-align: right;
}



.ComprasView .ProductosList {
    margin-bottom: 100px;
}

.ComprasView .ProductosList>div {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}


.ComprasView .ProductosList>div>div:first-child,
.ComprasView .ProductosList>div>div:nth-child(2) {
    text-align: left;
}

.ComprasView .ProductosList>div>div:not(:first-child):not(:nth-child(2)) {
    text-align: right;
}

.ComprasView .ProductosList>div>div:last-child>i {
    cursor: pointer;
}

.ComprasView .CompraPago {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.ComprasView .MediosDePago {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}

.ComprasView .AplicarCupon input {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;

}