.PaginateComponent{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.PaginateComponent > li{
    list-style-type: none;
    margin: 0 5px;
    font-size: 1em;
    box-sizing: border-box;
    padding: 0px 5px;
    text-align: center;
    border: #777 solid 1px;
    /* border-radius: 5px; */
    cursor: pointer;
    background: #f9f9f9;
}