.AudiosView {
    background: var(--primary-1);
    width: 100%;
    height: 100%;
    overflow: auto;
    position: relative;
}

.AudiosView_header{
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    padding: 20px;
}

.AudiosView_new{
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    background: transparent;
    border: none;
    color: red;
    padding: 10px;
    cursor: pointer;

}

.AudioListComponent {
    display: table;
    width: 100%;
}

.AudioListComponent > div {
    display: table-row;
}

.AudioListComponent > div:nth-child(odd) {
    background: var(--primary-2);
}

.AudioListComponent > div:hover{
    background: var(--green-3);
}

.AudioListComponent > div > div {
    display: table-cell;
    border-bottom: 1px solid var(--green-2);
    padding: 10px;
    vertical-align: top;
}


.AudioListComponent textarea {
    width: 100%;
    background: transparent;
    border: none;
    font-family: inherit;
    font-size: 1em;
    padding: 5px;;
    height: calc(1em + 10px);
    overflow: hidden;
    resize: none;
}

.AudioListComponent textarea:focus {
    background-color: rgba(255,255,255,0.25);
}

.AudioComponent {
    position: fixed;
    top: var(--header-height);
    right: 0;
    background: var(--green-2);
    padding: 30px;
    width: 400px;
    height: calc(100% - var(--header-height));
    overflow: hidden;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
}

.AudioListComponent a{
    color: inherit;
}

.AudioComponent_perfil span {
    display: flex;
    align-items: flex-start;
}

.AudioComponent_perfil {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    /* align-content: flex-end; */
}

span > sub {
    margin-right: 10px;
    font-style: italic;
}

.AudioComponent img {
    max-width: 100%;
    clip-path: circle();
    object-fit: cover;
}

.AudioComponent .AudioGoDemosComponent-volume {
    text-align: center;
}

.AudiosView__borrar{
    background: transparent;
    border: none;
    color: red;
    cursor: pointer;
    display: flex;
    align-items: center;
}