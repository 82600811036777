.AyudaView{
    padding: 50px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
}

.AyudaView .Tutoriales{
    display: flex;
    flex-wrap: wrap;
}

.AyudaView .Tutoriales>div{
    margin: 20px;
}

.AyudaView .Tutoriales video{
    width: 640px;
}



