.PublicProfileView {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding-bottom: 70px;
    overflow: auto;
    box-sizing: border-box;
}


.PublicProfileView nav {
    width: 100%;
    background: var(--primary-3);
    box-shadow: 0 0 20px var(--primary-3);
    color: var(--primary-10);
    height: 55px;
    position: fixed;
    bottom: 0px;
}

.PublicProfileView nav ul {
    width: 100%;
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: 0px;
    padding: 0px;
}

.PublicProfileView nav ul li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    padding: 10px 0px;
    cursor: pointer;
}

.PublicProfileView nav ul li.inactive {
    background: var(--secondary-1);
    color: var(--secondary-6);
}

.PublicProfileView nav ul li.inactive:hover {
    background: var(--primary-2);
    color: var(--primary-6);
}

.ProfileCard {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
    height: 100%;
    overflow: auto;
}

.ProfileCard .EnviarCorreo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
}

.ProfileCard .EnviarCorreo>div {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    border: 2px solid var(--secondary-8);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--secondary-8);
}

.ProfileCard .EnviarCorreo a {
    color: inherit;
    text-decoration: none;

}

.ProfileCard img {
    border-radius: 50%;
    width: 250px;
    min-height: 250px;
    border: 10px solid var(--primary-1);
    object-fit: cover;
}

.ProfileCard>div {
    width: 100%;
}

.ProfileCard>div:first-child {
    text-align: center;
}

.ProfileCard .cajas {
    display: flex;
    width: 100%;
    margin-top: 20px;
    flex-wrap: wrap;
    justify-content: center;
}

.ProfileCard .cajas>div {
    padding: 10px;
    border-radius: 10px;
    background-color: var(--primary-1);
    box-shadow: 0px 0px 10px var(--primary-2);
    margin: 10px;
    width: 200px;
    text-align: center;
}


.PublicDemoteca {
    text-align: center;
    height: 100%;
    width: 100%;
    overflow: auto;
    box-sizing: border-box;
}

.PublicDemoteca .filtros {
    width: 100%;
    box-sizing: border-box;
    padding: 0px 30px;
}

.PublicDemoteca .filtros button {
    background: var(--secondary-6);
    padding: 8px 20px;
    border-radius: 20px;
    display: inline-block;
    color: white;
    width: 100%;
    box-sizing: border-box;
    margin: 10px 0px;
    text-align: center;
    border: none;
    font-size: inherit;
    cursor: pointer;
    font-family: inherit;
}

.PublicDemoteca .filtros button.activo {
    background: var(--green-6);
    color: var(--green-10);
}

.PublicDemoteca .filtros ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    list-style: none;
    margin: 0px 0px 40px 0px;
    padding: 0px;
}

.PublicDemoteca .filtros ul.dont {
    display: none;
}

.PublicDemoteca .filtros ul li {
    background: #eee;
    color: var(--primary-10);
    padding: 8px 20px;
    border-radius: 20px;
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
    margin: 10px 0px;
    text-align: center;
    border: none;
    font-size: inherit;
    cursor: pointer;
}

.PublicDemoteca .filtros ul li:hover {
    background: #ccc;
    color: var(--green-1);
}

.PublicDemoteca .filtros ul li.active {
    background: #aaa;
    color: var(--green-1);
}

.PublicDemoteca .demo {
    margin: 40px 0px;
    text-align: center;
}

.PublicDemoteca .demo>div {
    display: inline-block;
}

.PublicMisDemos {
    text-align: center;
}

.PublicMisDemos .AudioGoDemosComponent {
    width: 350px;
    display: inline-block;
}

.PublicMisDemos-list {
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 30px;
    max-height: 400px;
    overflow: auto;
}

.PublicMisDemos-list>div {
    border-bottom: 1px solid var(--primary-1);
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 10px;
    text-align: left;
}

.PublicMisDemos-list>div.active {
    background: var(--primary-1);
}

@media (width>1024px) {
    .ProfileCard {
        flex-direction: row;
    }

    .PublicDemoteca .filtros {
        display: flex;
    }

    .PublicDemoteca .filtros>div {
        width: 100%;
        padding: 0px 20px;
        box-sizing: border-box;
    }

    .PublicDemoteca .filtros ul.dont {
        display: flex;
    }
}