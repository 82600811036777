.GrabarView {
    width: 100%;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
}

.SwitchInput {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: large;
}


.JustRecorded {
    background: var(--secondary-4);
    color: white;
    padding: 20px;
    box-shadow: 0px 0px 10px var(--secondary-8);
    margin: 30px 0px;
}

.JustRecorded button {
    background: var(--secondary-2);
    font-family: inherit;
    color: var(--secondary-9);
    border: none;
    padding: 10px 20px;
    box-shadow: 0px 0px 10px var(--secondary-3);
    border-radius: 10px;
    cursor: pointer;
    margin: 10px 0px;
}

.DemoElement__configuracion.filter-demo input {
    font-family: inherit;
    font-size: 1em;
    padding: 5px 0px;
    box-sizing: border-box;
    border: 0px;
    width: 100%;

}