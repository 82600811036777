.AudioTrackComponent{
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.AudioTrackComponent__bar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    font-family: monospace;
    width: 100%;
}

.AudioTrackComponent__bar input{
    width: 100%;

}

.AudioTrackComponent__controls{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}