@keyframes slideIn {
    from {
        transform: translateX(100%);
    } 
    to {
        transform: translateX(0);
    }
} 

.WelcomeView{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: auto;
    background-color: var(--secondary-1);
    color: black;
}

.Profile{
    width: 100%;
}

.Estadistica{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    padding: 20px;

}

.Estadistica > div{
    display: flex;
    align-items: center;
    padding: 10px 25px;
    background: white;
    margin: 10px;
    border-radius: 15px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
    width: 100%;
    height: 250px;
}

.Estadistica .TarifarioButton{
    color: var(--primary-9);
    padding: 0px 50px;
}

.Estadistica > div p{
    margin: 20px;
    font-size: 1.1em;
    color: var(--primary-8)
}

.Notificaciones{
    width: 100%;
    padding: 20px;
    border-radius: 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.Notificaciones > div{
    display: flex;
    flex-direction: row;
}

.Notificaciones-lista{
    display: flex;
    flex-direction: column;
    overflow: auto;
    max-width: 700px;
    min-width: 300px;
    margin: 10px 0px 10px 10px;
    background: white;
    border-radius: 15px 0px 0px 15px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
}

.Notificacion{
    margin: 10px 0px;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 10px;
    border-bottom: 1px solid var(--primary-1);
    cursor: pointer;

}

.Notificaciones-vista{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 20px;
    min-height: 300px;
    background: white;
    border-radius: 0px 15px 15px 0px;
    box-sizing: border-box;
    margin: 10px 10px 10px 0px;
    width: 100%;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
}

.Notificaciones-vista > div{
    animation: fadeIn 0.75s;
    width: 100%;
}

.Notificaciones-vista > div > div:first-child{
    border-bottom: 1px solid var(--primary-1);
    margin-bottom: 20px;
    padding-bottom: 10px;
}

.MisDemos{
    border-radius: 15px;
    padding: 20px;
    border-radius: 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.MisDemos-lista{
    background: var(--secondary-9);
    border-radius: 15px;
    color: white;
    padding: 20px;
    margin-top: 10px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
    min-width: 350px;
}

.MisDemos-lista .Demo>div:first-child{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.MisDemos-lista .Demo>div:first-child input{
    width: 100%;
    font-family: inherit;
    margin-right: 10px;
    font-size: inherit;
    background: transparent;
    border: none;
    color: inherit;
}

.MisDemos-lista .Demo>div:first-child i{
    cursor: pointer;
}

.Demo:not(:last-child){
    padding: 15px 0px;
    border-bottom: 1px solid var(--secondary-8);
}

.Demo:last-child{
    padding: 25px 0px;
    text-align: center;

}

.Demo:last-child input[type="file"]{
    display: none;
}

button.SubirDemo{
    border: none;
    background-color: var(--secondary-6);
    font-family: inherit;
    color: white;
    padding: 10px 30px;
    border-radius: 8px;
    margin: 20px 5px;
    display: inline-block;
    cursor: pointer;
}

.Demo > span{
    display: block;
}

.Profile-view{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    text-align: right;
    background: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    padding: 20px;
}

.Profile-edit{
    position: absolute;
    background: white;
    right: 0;
    display: flex;
    flex-direction: column;
    top: var(--header-height);
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    height: calc(100% - var(--header-height));
    box-sizing: border-box;
    animation: slideIn 0.5s;
    overflow: auto;
}

.Profile-edit > div {
    margin-bottom: 15px;
}

.Profile-edit textarea, .Profile-edit input, .Profile-edit select{
    font-family: inherit;
    border: 1px solid var(--primary-8);
    background-color: white;
    padding: 7px 12px;
    border-radius: 8px;
    margin: 10px 5px;
    display: inline-block;
    font-size: inherit;
    width: 100%;
    box-sizing: border-box;
}

.Profile-edit .ChoicesBox input{
    display: none;
}

.Profile-edit .ChoicesBox label{
    border: 1px solid var(--primary-8);
    background-color: var(--primary-1);
    padding: 7px 27px;
    border-radius: 8px;
    margin: 10px 5px;
    display: inline-block;
    cursor: pointer;
}

.Profile-edit .ChoicesBox label.checked{
    background-color: var(--primary-8);
    color: white;
}

.cash-coin-icon{
    margin-left: 20px;
    color: var(--green-10);
    cursor: pointer;
}

.plan-flex{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 3px 0px;
}

.plan-flex > span{
    padding: 3px 8px;
    border: 1px solid var(--secondary-6);
    border-radius: 4px;
    background: var(--secondary-2);
    color: var(--secondary-8);
    /* cursor: pointer; */
}