.MedioDePago{
    position: fixed;
    background: white;
    right: 0;
    display: flex;
    flex-direction: column;
    top: var(--header-height);
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    height: calc(100% - var(--header-height));
    box-sizing: border-box;
    animation: slideIn 0.5s;
    overflow: auto;
}