
.LocutoresView{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    overflow-x: auto;
    background-color: bisque;
}

.LocutoresList{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}

.LocutoresList > table{
    width: 100%;
    border-collapse: collapse;
}

.LocutoresList > table th{
    background-color: #f2f2f2;
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.LocutoresList > table td{
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    text-align: left;
}

.LocutoresList > table tr:hover td{
    background-color: #f1f1f1;
}

.LocutoresFilter{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    font-size: larger;
    padding: 20px 15px;
}

.LocutoresFilter input, .LocutoresFilter select{
    margin: 5px;
    padding: 5px;
    font-size: 1em;
    background-color: #f9f9f9;
}

.LocutorRowEdit td{
    background-color: lightyellow !important;
}