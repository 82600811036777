.DescuentoView {
    padding: 30px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.DescuentoView .Mensaje{
    margin: 10px;
    padding: 20px;
    border-radius: 20px;
    background-color: var(--green-1);
}

.DescuentoView .DescuentoViewContent {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 978px;
}

.DescuentoView .DescuentoViewContent>div{
    margin: 10px;
    box-sizing: border-box;
    padding: 20px;
}

.DescuentoView .DescuentoViewContent .Resumen{
    border-radius: 20px;
    text-align: right;
}

.DescuentoView .DescuentoViewContent .Resumen img{
    cursor: pointer;
}