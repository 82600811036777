.RecordField{
    padding: 8px;
    display: block;
    width: 100%;
    text-wrap: wrap;
}

.InputEdit{
    position: relative;
}

.InputEdit input[type="text"], .InputEdit select{
    font-family: inherit;
    font-size: 14px;
    color: #333;
    line-height: 1.5;
    border: 1px solid #333;
    background-color: white;
    padding: 5px;
    position: absolute;
    box-shadow: gray 0px 0px 10px;
  }