@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.ModalContainer{
    position: fixed;
    display: flex;
    flex-direction: column;
    height: calc(100% - var(--header-height));
    top: var(--header-height);
    left: 0px;
    width: 100%;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    z-index: 10;
    animation: fadeIn 1s;
}

.ModalContainer__content{
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    background: white;
    /* border-radius: 15px; */
    width: 100%;
    /* box-shadow: gray 0px 0px 30px; */
    height: 100%;
    overflow: auto;
}