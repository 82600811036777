@font-face {
  font-family: 'Montserrat';
  src: url('../public/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('../public/Montserrat-SemiBold.ttf') format('truetype');
}

:root {
  --header-height: 75px;

  --primary-1: #f3eeff;
  --primary-2: #e3d5ff;
  --primary-3: #cdb4ff;
  --primary-4: #b691ff;
  --primary-5: #a070ff;
  --primary-6: #8b51ff;
  --primary-7: #7645d9;
  --primary-8: #633ab5;
  --primary-9: #4f2e91;
  --primary-10: #3f2473;

  --secondary-1: #fff0f9;
  --secondary-2: #ffdaf1;
  --secondary-3: #ffbde6;
  --secondary-4: #ff9fda;
  --secondary-5: #ff82cf;
  --secondary-6: #ff66c4;
  --secondary-7: #d957a7;
  --secondary-8: #b5488b;
  --secondary-9: #913a70;
  --secondary-10: #732e58;

  --green-1: #f8ffe6;
  --green-2: #efffc2;
  --green-3: #e3ff91;
  --green-4: #d5ff5e;
  --green-5: #c9ff2e;
  --green-6: #bdff00;
  --green-7: #a1d900;
  --green-8: #86b500;
  --green-9: #6c9100;
  --green-10: #557300;
}

html,
body {
  margin: 0px;
  padding: 0px;
  height: 100%;
  width: 100%;
}

div {
  box-sizing: border-box;
}

#root {
  height: 100%;
  width: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

* {
  scrollbar-width: thin;
  scrollbar-color: black white;
}


.GoDemos {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Montserrat, sans-serif;
  color: black;
}

.GoDemos>header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: var(--header-height);
  box-sizing: border-box;
}

.GoDemos>div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: black;
  width: 100vw;
  height: calc(100% - var(--header-height));
  box-sizing: border-box;
}

@keyframes activityFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }

}

.Activity {
  height: 100%;
  width: 100%;
  overflow-x: auto;
}

.Activity>div {
  animation: activityFadeIn 0.5s;
}

.CheckLogin {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--primary-1);
}

.CheckLogin-mensaje {
  padding: 10px;
  margin: 10px 0px;
  border-radius: 15px;
  background: var(--green-2);
  color: var(--green-9);
  border: 2px solid var(--green-5);
}

button.standard{
  padding: 10px 20px;
  margin: 10px;
  border-radius: 15px;
  background: var(--green-6);
  color: white;
  border: 2px solid var(--green-7);
  font-family: inherit;
  font-weight: bold;
  font-size: 1em;
  cursor: pointer;
}

button.standard:hover{
  background: var(--green-7);
}