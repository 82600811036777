@font-face {
    /* 
    Origin: GitHub
    Repository: https://github.com/RouninMedia/password-font?tab=readme-ov-file
     */
    font-family: 'password';
    src: url('/public/password.ttf');
    font-display: block;
    
}

.LoginView{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 60px;
    border-radius: 15px;
    background-color: var(--primary-3);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    color: black;
}

.LoginView form{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.LoginView form input{
    width: 100%;
    padding: 10px;
    margin: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.LoginView form button{
    width: 100%;
    padding: 10px;
    margin: 15px;
    border: none;
    border-radius: 10px;
    background-color: var(--secondary-5);
    color: white;
    font-weight: bold;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    font-family: inherit;
    cursor: pointer;
}

.LoginView form button:hover{
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
}

.LoginView div.link{
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 0.8em;
    font-weight: bold;
    color: var(--secondary-10);
    justify-content: center;
    width: 100%;
    margin: 5px 0px;
}

.LoginView div.link:hover{
    color: var(--secondary-8);
}

.LoginView div.boton{
    padding: 5px;
    border: 2px solid var(--secondary-5);
    border-radius: 10px;
}

.LoginView div.boton:hover{
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
}

.SignupModal {
    background: var(--secondary-2);
    position: fixed;
    padding: 10px 20px;
    border-radius: 15px;
    box-shadow: 0px 0px 25px var(--secondary-9);
    color: var(--primary-10);
    max-width: 500px;
    top: calc(50% - 100px);
    text-align: center;
}

.SignupModal hr{
    border-top: var(--secondary-8) 1px solid;
}

.SignupModal button{
    background: var(--primary-8) !important;
    box-sizing: border-box;
    margin: 10px 0px !important;
}

button.cancelar{
    background: var(--secondary-8) !important;
}

.password{
    font-family: 'password';
}

.password:active{
    font-family: monospace;
}

.password i{
    margin-left: 5px;
    cursor: pointer;
}

