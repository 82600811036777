.MenuLeftView{
    display: flex;
    flex-direction: column;
    height: 100%;
    background: white;
    color: #131313;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    z-index: 2;
}

.MenuLeftView ul{
    margin: 0px;
    padding: 0px;
}

.MenuLeftView li::marker{
    content: '';
}

.MenuLeftView li{
    list-style-type: none;
    padding: 10px 25px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.MenuLeftView li > a{
    text-decoration: none;
    display: flex;
    align-items: center;
    color: inherit;
}

.MenuLeftView span{
    margin-left: 10px;
}

.MenuLeftView .selected{
    background: #f2eeff;
    color:#8b51fe;
}