

.ReelsView{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background: var(--green-7);
}

.IconLighter{
    background: white;
    width: 128px;
    border-radius: 74px;
    height: 128px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 48px 0px #dfdfdf;
    border: 10px solid #ffeedd;
}

.Button-I-want{
    background: var(--purple);
    color: white;
    font-family: inherit;
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 24px 0px white;
    cursor: pointer;
    font-weight: bold;
}

 @keyframes mostrar
 {
     from{
         right: -100%;
     }
     to{
         right: 0px;
     }
    
 }

.ReelsUpload{
    position: fixed;
    top: var(--header-height);
    right: 0px;
    height: calc(100% - var(--header-height));
    background-color: white;
    padding: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    animation: mostrar 0.5s !important;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;

}

.FileUpload{
    width: 100%;
    border: 1px solid #dfdfdf;
    display: block;
    background: #fafafa;
    border-radius: 15px;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
}

.ReelsUpload button{
    background: var(--purple);
    color: white;
    font-family: inherit;
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: bold;
    margin: 10px;
}

.ReelsUpload .Button-container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
}

.ReelsUpload-list > div{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #dfdfdf;
}

@keyframes spinClockwise {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.cassette{
    display: flex;
    justify-content: space-between;
    border-bottom: 5px solid black;
    border-radius: 75px;
    margin-bottom: 20px;
}

.spining-clockwise {
    animation: spinClockwise 1s linear infinite;
    color: #ffeebb;
    background: black;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    margin-bottom: -5px;
}