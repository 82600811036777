@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

.AudiosView {
    background: var(--primary-1);
    width: 100%;
    height: 100%;
    overflow: auto;
    position: relative;
}


.AudiosView_new{
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    background: transparent;
    border: none;
    color: red;
    padding: 10px;
    cursor: pointer;

}

.CastingListComponent {
    display: table;
    width: 100%;
}

.CastingListComponent > div {
    display: table-row;
}

.CastingListComponent > div:nth-child(odd) {
    background: var(--primary-2);
}

.CastingListComponent > div:hover{
    background: var(--green-3);
}

.CastingListComponent > div > div {
    display: table-cell;
    border-bottom: 1px solid var(--green-2);
    padding: 10px;
    vertical-align: middle;
}


.CastingListComponent textarea {
    width: 100%;
    background: transparent;
    border: none;
    font-family: inherit;
    font-size: 1em;
    padding: 5px;;
    height: calc(1em + 10px);
    overflow: hidden;
    resize: none;
}

.CastingListComponent textarea:focus {
    background-color: rgba(255,255,255,0.25);
}

.AudioComponent {
    position: fixed;
    top: var(--header-height);
    right: 0;
    background: var(--green-2);
    padding: 30px;
    width: 400px;
    height: calc(100% - var(--header-height));
    overflow: hidden;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
}

.CastingListComponent a{
    color: inherit;
}

.AudioComponent_perfil span {
    display: flex;
    align-items: flex-start;
}

.AudioComponent_perfil {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    /* align-content: flex-end; */
}

span > sub {
    margin-right: 10px;
    font-style: italic;
}

.AudioComponent img {
    max-width: 100%;
    clip-path: circle();
    object-fit: cover;
}

.AudioComponent .AudioGoDemosComponent-volume {
    text-align: center;
}

.CastingComponent{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: fixed;
    right: 0px;
    background: var(--green-1);
    padding: 40px;
    height: calc(100% - var(--CRM-Menu-height));
    z-index: 2;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    animation: slideInFromRight 0.5s ease;
    overflow: scroll;
}

.CastingComponent span {
    background: var(--green-2);
    padding: 10px 20px;
    margin: 5px 0px;
    display: block;
    border-radius: 5px;
    box-sizing: border-box;
    width: 400px;
}