.TarifarioView{
    padding: 30px;
    background: var(--green-1);
    height: 100%;
}

.TarifarioView .listado{
    border-collapse: collapse;
    margin-top: 20px;
}

.TarifarioView .listado td, .TarifarioView .listado th{
    padding: 10px 50px;
}

.TarifarioView .listado th{
    background: var(--green-9);
    color: var(--green-1);
}

.TarifarioView .listado td{
    background: var(--green-2);
    color: black;
}

.TarifarioView .listado tr:nth-child(even) td{
    background: var(--green-3);
}

.TarifarioView .listado tr:hover td{
    background: var(--green-4);
}