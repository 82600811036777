
.Demoteca{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
}

.Demoteca_itemlist{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 30%;
    box-sizing: border-box;

}

.Demoteca_itemlist h1{
    width: 100%;
    text-align: center;
    margin: 10px 0;
    padding: 10px 0;
    background-color: var(--purple);
    font-size: 18px;
    color: white;
    font-weight: normal;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.Demoteca_item{
    width: 100%;
    text-align: center;
    background-color: rgb(224, 224, 224);
    margin: 5px 0;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 10px;
}

.Demoteca_item:hover{
    background-color: rgb(200, 200, 200);
}

.Demoteca_item.selected{
    background-color: rgb(180, 180, 180);
}

.Demoteca_item.unavailable{
    color: rgb(150, 150, 150);
}

.Demoteca_audio{
    display: flex;
    flex-direction: column;
    align-items: center;
}

button.Demoteca-grabar-demo{
    margin: 10px 0;
    padding: 10px 15px;
    background-color: var(--purple);
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    font-family: inherit;
    font-size: 1.2em;
    display: flex;
    align-items: center;
}


.DemoRecord{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
}

.DemoRecord_breadcrumb{
    background: rgba(0,0,0,0.1);
    padding: 5px 10px;
    margin: 10px 0;
    border-radius: 5px;
}

.DemoRecord__content{
    display: flex;
    flex-direction: row;
}

.DemoRecord__text textarea{
    width: 400px;
    height: 600px;
    margin: 10px 0;
    padding: 15px;
    box-sizing: border-box;
    max-height: 100%;
    overflow-y: auto;
}

.DemoRecord__text button{
    margin: 10px 0;
    padding: 10px 15px;
    background-color: var(--purple);
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    font-family: inherit;
    font-size: 1.2em;
    display: flex;
    align-items: center;
}